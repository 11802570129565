import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-browser-refresh-dialog',
  templateUrl: './browser-refresh-dialog.component.html',
  styleUrls: ['./browser-refresh-dialog.component.scss']
})
export class BrowserRefreshDialogComponent implements OnInit {

  @Input() display:boolean = false;
  @Output() onUpdated = new EventEmitter<boolean>(false);

  constructor() { }

  ngOnInit() {
  }

  public onCancel() {
    this.display = false;
    this.onUpdated.emit(false);
  }

  public onReject() {
    this.onCancel();
  }

  public onAccept() {
    this.display = false;
    this.onUpdated.emit(true);
  }
}
