<p-dialog header="{{getHeaderText}}" [(visible)]="display" [modal]="true" (onHide)="onCancel()" [draggable]="false" [style]="{width: '1100px', height:'1500px'}" position="center center" [closeOnEscape]="false">
  <div *ngIf="currentView == 1 && isLoaded">
    <report-wellness-details #wellnessdetailscomponent
                            [selectedMemberAWPStatus]="selectedMemberAWPStatus"
                            [selectedMember]="selectedMember"
                            [wellnessDetails]="wellnessDetails"
                            [providerDetail]="providerDetail"
                            [memberDetail]="memberDetail"
                            (onItemChange)="onWellnessFormChanges($event)">
    </report-wellness-details>
  </div>
  <div *ngIf="currentView == 2 && isLoaded">
    <report-wellness-attachments #wellnessattachmentcomponent
                            [selectedMemberAWPStatus]="selectedMemberAWPStatus"
                            [selectedMember]="selectedMember"
                            [wellnessFileList]="wellnessFileList"
                            (onFileAdded)="fileAdded($event)"
                            (onFileRemoved)="fileRemoved($event)">
    </report-wellness-attachments>
  </div>
  <div *ngIf="currentView == 3 && isLoaded">
    <report-wellness-survey #surveycomponent
                            [wellnessSurveys]="wellnessSurveys">
    </report-wellness-survey>
  </div>
  <p-footer>
    <div *ngIf="currentView == 1 && isLoaded" class="awp-dialog-footer">
      <span class="required-field">* Required field</span>
    </div>
    <div *ngIf="hasUploadError2" class="alert-error">
      <app-alert-danger *ngIf="hasUploadError2" #alertdangermessage
                        [uniqueID]="validationerror"
                        [subjectMessage]="' '"
                        [contentMessage]="errorMessage">
      </app-alert-danger>
    </div>
    <div>
      <button *ngIf="currentView == 1" type="button" class="btn btn-primary btn-skip" (click)="onSkip()">Manual Upload</button>      
      <button *ngIf="isSubmitted" type="button" class="btn btn-primary btn-download" (click)="onPrintToPdf()">Print</button>
      <button type="button" class="btn btn-secondary btn-cancel" (click)="onCancelWellness()">{{getCancelButtonText}}</button>
      <button type="button" class="btn btn-primary btn-savelater" (click)="onSaveAndContinueLater()">Save and Continue Later</button>
      <button type="button" class="btn btn-primary btn-create" (click)="onApplyWellness()">{{getSubmitButtonText}}</button>      
    </div>
  </p-footer>
</p-dialog>
