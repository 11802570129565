import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PortalConfig } from '../portal-config';
import { AppConfig, HttpResponseHelper } from '@caloptima/portal-foundation';
import { Observable } from 'rxjs';
import { MemberRoster } from './models/member-roster';
import { BHABAReferralDownloadRequest, BHIDownloadRequest, MemberPcprosterDownloadRequest } from './models/requests/member-pcproster-download-request';
import { OAuthService } from 'angular-oauth2-oidc';
import { SessionService } from './session.service';
import { ReportFile } from './models/report-file';
import { ReportFilesForProviderRequest } from './models/requests/reportfiles-forprovider-request';
import { TaxSearchReportRequest } from './models/requests/tax-search-report-request';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { ReportFileDownloadForProviderRequest } from './models/requests/reportfile-download-request';
import { ProviderSearchReportRequest } from './models/requests/provider-search-memberroster-request';
import { ProviderDetail, ProviderDetailWithAttestation } from './models/provider-detail';
import { Constants } from '../app.constants';
import { ReportType } from './models/reportype';
import { ReportFilesForMemberRequest, ReportFilesForTaxRequest } from './models/requests/reportfiles-formember-request';
import { MemberSearchReportRequest } from './models/requests/member-search-report-request';
import { Member } from './models/member';
import { ReportMemberDetails } from './models/reportmemberdetails';
import { AttestationDetail } from './models/attestation-detail';
import { AttestationRequest } from './models/requests/attestation-request';
import { ProviderTaxDetails } from './models/provider-tax-details';
import { MemberAWPStatus } from './models/awp-detail';
import { WellnessDetails, WellnessDetails2Extend } from './models/wellness-details';
import { ScriptQuestionAnswer } from './models/script-question-answer';
import { MemberWithHifDownloadRequest } from './models/requests/member-with-hif-download-request';
import { wellnessSurveyAnswer } from './models/requests/wellnessSurveyQuestionAnswer';
import { wellnessSurvey } from './models/wellnessSurvey';
import { WellnessSurveyAnswers, wellnessRequest } from './models/requests/wellnessSurveyAnswerRequest';
import { BHIMeasurementPeriod } from './models/responses/bhi-measurement-period';
import { AWPDownloadRequest } from './models/requests/awp-download-request';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private currentUser: string;
  private baseProviderReportServiceUrl: string;
  private downloadMemberPcpRosterUrl: string;
  private downloadBHABAReferralUrl: string;
  private downloadIHADueMemberUrl: string;
  private downloadReferralSudmissionPdfUrl: string;
  private downloadReferralDetailPdfUrl: string;
  private getReportFilesForProviderUrl: string;
  private getReportFilesForMemberUrl: string;
  private getReportFilesForTaxUrl: string;
  private getReportFileDownloadForProviderUrl: string;
  private getReportWellnessDetailsUrl: string;
  private getProvidersForMemberRosterUrl: string;
  private getProvidersForIHAUrl: string;
  private getProvidersForReportUrl: string;
  private getTaxesForReportUrl: string;
  private searchProvidersForReportUrl: string;
  private getMembersForReportUrl: string;
  private searchMembersForReportUrl: string;
  private searchTaxesForReportUrl: string;
  private searchProvidersForPCPMembershipRosterReport: string;
  private searchProvidersForIHAReportUrl: string;
  private downloadClaimDetailPdfUrl: string;
  private getProvidersWithAttestationUrl: string;
  private searchProvidersWithAttestationUrl: string;
  private getAttestationListForProviderUrl: string;
  private applyAttestationUrl: string;
  private applyWellnessUrl: string;
  private applyWellness2Url: string;
  private applyWellness2SaveUrl: string;
  private applyWellnessSurveyAnswersUrl: string;
  private getProvidersForReportAWPUrl: string;
  private getProvidersForReportAWP2Url: string;
  private getMembersForReportAWPUrl: string;
  private getMembersForReportAWP2Url: string;
  private searchMembersForReportAWPUrl: string;
  private searchMembersForReportAWP2Url: string;
  private searchProvidersForReportAWPUrl: string;
  private searchProvidersForReportAWP2Url: string;
  private getReportFilesForMemberAWPUrl: string;
  private getReportFilesForMemberAWP2Url: string;
  private getReportWellnessSurveyUrl: string;
  private getProvidersForReportHIFUrl: string;
  private getMembersForReportHIFUrl: string;
  private searchMembersForReportHIFUrl: string;
  private searchProvidersForReportHIFUrl: string;
  private getMemberHifDataUrl: string;
  private downloadHifMemberListUrl: string;
  private downloadMemberHifDetailpdfUrl: string;
  private downloadAWPDetailpdfUrl: string;
  private downloadUnansweredAWPDetailpdfUrl: string;
  private getProvidersForBHIUrl: string;
  private SearchProvidersForBHIUrl: string;
  private downloadbhimembersUrl: string;
  private getBHIMeasurementPeriodsUrl: string;
  public newWellnessForm: boolean = true;

  constructor(
    private oAuthService: OAuthService,
    private http: HttpClient,
    private portalConfig: PortalConfig,
    private appConfig: AppConfig,
    private constants: Constants,
    private sessionService: SessionService) {

    const claims = this.oAuthService.getIdentityClaims();
    this.currentUser = claims['sub'];
    this.baseProviderReportServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
    this.newWellnessForm = appConfig.getConfig('UseNewWellnessForm').trim().toLowerCase() === "true";
    if (this.baseProviderReportServiceUrl == null) {
      const config$ = appConfig.subscribe(() => {
        this.baseProviderReportServiceUrl = appConfig.getConfig('BaseProviderServicesApiUrl');
        this.checkUrls();
        config$.unsubscribe();
      });
    }
  }

  private checkUrls(): void {
    if (this.downloadMemberPcpRosterUrl == null) {
      const baseUrl = this.baseProviderReportServiceUrl + 'api/report/';
      this.downloadMemberPcpRosterUrl = baseUrl + 'downloadmemberpcproster';
      this.downloadBHABAReferralUrl = baseUrl + 'downloadbhabareferrals';
      this.downloadIHADueMemberUrl = baseUrl + 'downloadihaduemembers';
      this.downloadReferralSudmissionPdfUrl = baseUrl + 'downloadreferralsubmissionpdf';
      this.downloadReferralDetailPdfUrl = baseUrl + 'downloadreferraldetailpdf';
      this.getReportFilesForTaxUrl = baseUrl + 'getReportFilesForTaxes';
      this.getReportFilesForProviderUrl = baseUrl + 'getReportFilesForProvider';
      this.getReportFilesForMemberUrl = baseUrl + 'getReportFilesForMember';
      this.getReportFileDownloadForProviderUrl = baseUrl + 'downloadReportFileForProvider';
      this.getProvidersForMemberRosterUrl = baseUrl + 'getProvidersForPCPMemberRoster';
      this.getProvidersForIHAUrl = baseUrl + 'getProvidersForIHA';
      this.getProvidersForReportUrl = baseUrl + 'getProvidersForReport';
      this.getTaxesForReportUrl = baseUrl + 'getTaxesForReport';
      this.getMembersForReportUrl = baseUrl + 'getMembersForReport'
      this.downloadClaimDetailPdfUrl = baseUrl + 'downloadclaimdetailpdf';
      this.searchTaxesForReportUrl = baseUrl + 'searchTaxesForReport';
      this.searchMembersForReportUrl = baseUrl + 'searchMembersForReport';
      this.searchProvidersForReportUrl = baseUrl + 'searchProvidersForReport';
      this.searchProvidersForPCPMembershipRosterReport = baseUrl + 'searchProvidersForPCPMembershipRosterReport';
      this.searchProvidersForIHAReportUrl = baseUrl + 'searchProvidersForIHA';
      this.getProvidersWithAttestationUrl = baseUrl + 'getProvidersWithAttestation';
      this.searchProvidersWithAttestationUrl = baseUrl + 'searchProvidersWithAttestation';
      this.getAttestationListForProviderUrl = baseUrl + 'getAttestationListForProvider';
      this.applyAttestationUrl = baseUrl + 'applyattestations';
      this.getProvidersForReportAWPUrl = baseUrl + 'getProvidersForReportAWP';
      this.getProvidersForReportAWP2Url = baseUrl + 'getProvidersForReportAWP2';
      this.searchProvidersForReportAWPUrl = baseUrl + 'searchProvidersForReportAWP';
      this.searchProvidersForReportAWP2Url = baseUrl + 'searchProvidersForReportAWP2';
      this.getMembersForReportAWPUrl = baseUrl + 'getMembersForReportAWP'
      this.getMembersForReportAWP2Url = baseUrl + 'getMembersForReportAWP2'
      this.searchMembersForReportAWPUrl = baseUrl + 'searchMembersForReportAWP';
      this.searchMembersForReportAWP2Url = baseUrl + 'searchMembersForReportAWP2';
      this.getReportFilesForMemberAWPUrl = baseUrl + 'getReportFilesForMemberAWP';
      this.getReportFilesForMemberAWP2Url = baseUrl + 'getReportFilesForMemberAWP2';
      this.getReportWellnessSurveyUrl = baseUrl + 'getReportWellnessSurvey';
      this.applyWellnessUrl = baseUrl + 'applywellness';
      this.applyWellness2Url = baseUrl + 'applywellness2';
      this.applyWellness2SaveUrl = baseUrl + 'applywellness2save';
      this.applyWellnessSurveyAnswersUrl = baseUrl + 'applywellnesssurveyanswers';
      this.getProvidersForReportHIFUrl = baseUrl + 'getProvidersForReportHIF';
      this.searchProvidersForReportHIFUrl = baseUrl + 'searchProvidersForReportHIF';
      this.getMembersForReportHIFUrl = baseUrl + 'getMembersForReportHIF'
      this.searchMembersForReportHIFUrl = baseUrl + 'searchMembersForReportHIF';
      this.getMemberHifDataUrl = baseUrl + 'getMemberHifData';
      this.downloadHifMemberListUrl = baseUrl + 'downloadhifmemberlist';
      this.downloadMemberHifDetailpdfUrl = baseUrl + 'downloadmemberhifdetailpdf';
      this.getReportWellnessDetailsUrl = baseUrl + 'getReportWellnessDetails';
      this.downloadAWPDetailpdfUrl = baseUrl + 'downloadawpdetailpdf';
      this.downloadUnansweredAWPDetailpdfUrl = baseUrl + 'downloadUnansweredAWPDetailpdf';
      this.getProvidersForBHIUrl = baseUrl + 'getProvidersForBHI';
      this.SearchProvidersForBHIUrl = baseUrl + 'searchProvidersForBHI';
      this.downloadbhimembersUrl = baseUrl + 'downloadbhimembers';
      this.getBHIMeasurementPeriodsUrl = baseUrl + 'getBHIMeasurementPeriods';
    }
  }

  public getProvidersForPCPMemberRoster(collectionId: number): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = true;

      return this.http
        .post<ProviderDetail[]>(this.getProvidersForMemberRosterUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getProvidersForBHABAReferrals(collectionId: number): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = false;

      return this.http
        .post<ProviderDetail[]>(this.getProvidersForMemberRosterUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getProvidersForIHA(collectionId: number): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = true;

      return this.http
        .post<ProviderDetail[]>(this.getProvidersForIHAUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getProvidersForBHI(collectionId: number): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = true;

      return this.http
        .post<ProviderDetail[]>(this.getProvidersForBHIUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public searchProvidersForPCPMemberRoster(providerId: string, providerName: string): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.providerName = providerName;
      request.calProviderId = providerId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = true;

      return this.http
        .post<ProviderDetail[]>(this.searchProvidersForPCPMembershipRosterReport, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public searchProvidersForBHABAReferrals(providerId: string, providerName: string): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.providerName = providerName;
      request.calProviderId = providerId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = false;

      return this.http
        .post<ProviderDetail[]>(this.searchProvidersForPCPMembershipRosterReport, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }  
  public searchProvidersForIHA(providerId: string, providerName: string): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.providerName = providerName;
      request.calProviderId = providerId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = true;

      return this.http
        .post<ProviderDetail[]>(this.searchProvidersForIHAReportUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public searchProvidersForBHI(providerId: string, providerName: string): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.providerName = providerName;
      request.calProviderId = providerId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = true;

      return this.http
        .post<ProviderDetail[]>(this.SearchProvidersForBHIUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getProvidersForReports(collectionId: number, reportType: ReportType): Observable<ProviderDetail[]> {

    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = false;
      request.reportType = reportType;
      return this.http
        .post<ProviderDetail[]>(this.getProvidersForReportUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getProvidersForReportsAWP(collectionId: number, reportType: ReportType): Observable<ProviderDetail[]> {

    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = false;
      request.reportType = reportType;
      return this.http
        .post<ProviderDetail[]>(this.newWellnessForm ? this.getProvidersForReportAWP2Url : this.getProvidersForReportAWPUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getProvidersForReportsHIF(collectionId: number, reportType: ReportType): Observable<ProviderDetail[]> {

    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = false;
      request.reportType = reportType;
      return this.http
        .post<ProviderDetail[]>(this.getProvidersForReportHIFUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public searchProvidersForReports(providerId: string, providerName: string, reportType: ReportType): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.providerName = providerName;
      request.calProviderId = providerId;
      request.reportType = reportType;

      return this.http
        .post<ProviderDetail[]>(this.searchProvidersForReportUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {

    }
  }

  public searchProvidersForReportsAWP(providerId: string, providerName: string, reportType: ReportType): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.providerName = providerName;
      request.calProviderId = providerId;
      request.reportType = reportType;

      return this.http
        .post<ProviderDetail[]>(this.newWellnessForm ? this.searchProvidersForReportAWP2Url : this.searchProvidersForReportAWPUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {

    }
  }

  public searchProvidersForReportsHIF(providerId: string, providerName: string, reportType: ReportType): Observable<ProviderDetail[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.providerName = providerName;
      request.calProviderId = providerId;
      request.reportType = reportType;

      return this.http
        .post<ProviderDetail[]>(this.searchProvidersForReportHIFUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {

    }
  }

  public searchMembersForReports(reportType: ReportType, collectionId: number, memberName: string, memberId: string): Observable<ReportMemberDetails[]> {
    this.checkUrls();

    try {
      const request = new MemberSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.reportType = reportType;
      request.memberId = memberId;
      request.memberName = memberName;

      return this.http
        .post<ReportMemberDetails[]>(this.searchMembersForReportUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public searchMembersForReportsAWP(reportType: ReportType, collectionId: number, memberName: string, memberId: string): Observable<ReportMemberDetails[]> {
    this.checkUrls();

    try {
      const request = new MemberSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.reportType = reportType;
      request.memberId = memberId;
      request.memberName = memberName;

      return this.http
        .post<ReportMemberDetails[]>(this.searchMembersForReportAWPUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public searchMembersForReportsAWP2(reportType: ReportType, collectionId: number, memberName: string, memberId: string): Observable<ReportMemberDetails[]> {
    this.checkUrls();

    try {
      const request = new MemberSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.reportType = reportType;
      request.memberId = memberId;
      request.memberName = memberName;

      return this.http
        .post<ReportMemberDetails[]>(this.newWellnessForm ? this.searchMembersForReportAWP2Url : this.searchMembersForReportAWPUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public searchMembersForReportsHIF(reportType: ReportType, collectionId: number, memberName: string, memberId: string): Observable<ReportMemberDetails[]> {
    this.checkUrls();

    try {
      const request = new MemberWithHifDownloadRequest();

      request.username = this.currentUser;
      request.collectionId = collectionId;
      request.memberCin = memberId;
      request.isExternalUser = !(this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser);

      return this.http
        .post<ReportMemberDetails[]>(this.searchMembersForReportHIFUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getMembersForReports(collectionId: number, providerId: string, reportType: ReportType): Observable<ReportMemberDetails[]> {
    this.checkUrls();

    try {
      const request = new MemberSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.calProviderId = providerId;
      request.reportType = reportType;
      return this.http
        .post<ReportMemberDetails[]>(this.getMembersForReportUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getMembersForReportsAWP(collectionId: number, providerId: string, providerTaxId: string, reportType: ReportType): Observable<ReportMemberDetails[]> {
    this.checkUrls();

    try {
      const request = new MemberSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.calProviderId = providerId;
      request.reportType = reportType;
      request.providerTaxId = providerTaxId;

      return this.http
        .post<ReportMemberDetails[]>(this.getMembersForReportAWPUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getMembersForReportsAWP2(collectionId: number, providerId: string, providerTaxId: string, reportType: ReportType): Observable<ReportMemberDetails[]> {
    this.checkUrls();

    try {
      const request = new MemberSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.calProviderId = providerId;
      request.reportType = reportType;
      request.providerTaxId = providerTaxId;

      return this.http
        .post<ReportMemberDetails[]>(this.newWellnessForm ? this.getMembersForReportAWP2Url : this.getMembersForReportAWPUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getMembersForReportsHIF(collectionId: number, providerId: string, providerTaxId: string, reportType: ReportType): Observable<ReportMemberDetails[]> {
    this.checkUrls();

    try {
      const request = new MemberWithHifDownloadRequest();

      request.username = this.currentUser;
      request.providerIds.push(providerId);
      request.collectionId = 0;
      request.isExternalUser = !(this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser);;

      return this.http
        .post<ReportMemberDetails[]>(this.getMembersForReportHIFUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getMemberHifData(cin: string): Observable<ScriptQuestionAnswer[]> {
    this.checkUrls()

    try {
      const request = new MemberSearchReportRequest();

      request.userName = this.currentUser;
      request.memberId = cin;

      return this.http
        .post<ScriptQuestionAnswer[]>(this.getMemberHifDataUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public getTaxesForReports(collectionId: number, reportType: ReportType): Observable<ProviderTaxDetails[]> {

    this.checkUrls();

    try {
      const request = new TaxSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.reportType = reportType;

      return this.http
        .post<ProviderTaxDetails[]>(this.getTaxesForReportUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public searchTaxForReports(reportType: ReportType, taxName: string, taxId: string): Observable<ProviderTaxDetails[]> {
    this.checkUrls();

    try {
      const request = new TaxSearchReportRequest();

      request.userName = this.currentUser;
      request.reportType = reportType;
      request.taxId = taxId;
      request.taxName = taxName;

      return this.http
        .post<ProviderTaxDetails[]>(this.searchTaxesForReportUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public downloadMemberPcpRosterUsingCollectionId(collectionId: number): Observable<Blob> {
    this.checkUrls();
    var request = new MemberPcprosterDownloadRequest();
    request.username = this.currentUser;
    request.collectionId = collectionId;

    return this.http.post(this.downloadMemberPcpRosterUrl, request, {
      responseType: 'blob'
    });
  }

  public getReportFilesForProvider(calProviderId: string, reportType: ReportType): Observable<ReportFile[]> {
    this.checkUrls();
    var request = new ReportFilesForProviderRequest();
    request.userName = this.currentUser;
    request.reportType = reportType;
    request.providerIds.push(calProviderId);


    return this.http.post<ReportFile[]>(this.getReportFilesForProviderUrl, request, httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return HttpResponseHelper.handleError(error);
        })
      );
  }

  public getBHIMeasurementPeriods(calProviderId: string): Observable<BHIMeasurementPeriod[]> {
    this.checkUrls();

    const url = this.getBHIMeasurementPeriodsUrl + '?calProviderId=' + calProviderId
    return this.http.get<BHIMeasurementPeriod[]>(url)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return HttpResponseHelper.handleError(error);
        })
      );
  }

  public getReportFilesForMember(calProviderId: string, memberId: string, reportType: ReportType): Observable<ReportFile[]> {
    this.checkUrls();
    var request = new ReportFilesForMemberRequest();
    request.userName = this.currentUser;
    request.reportType = reportType;
    request.providerId = calProviderId;
    request.memberids.push(memberId);


    return this.http.post<ReportFile[]>(this.getReportFilesForMemberUrl, request, httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return HttpResponseHelper.handleError(error);
        })
      );
  }

  public getReportFilesForMemberAWP(calProviderId: string, memberId: string, grgr_ck: number, providerTin: string, reportType: ReportType): Observable<MemberAWPStatus[]> {
    this.checkUrls();

    var request = new ReportFilesForMemberRequest();
    request.userName = this.currentUser;
    request.reportType = reportType;
    request.providerId = calProviderId;
    request.grgr_ck = grgr_ck;
    request.providerTin = providerTin;
    request.memberids.push(memberId);


    return this.http.post<MemberAWPStatus[]>(this.getReportFilesForMemberAWPUrl, request, httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return HttpResponseHelper.handleError(error);
        })
      );
  }

  public getReportFilesForMemberAWP2(calProviderId: string, memberId: string, grgr_ck: number, providerTin: string, reportType: ReportType): Observable<MemberAWPStatus[]> {
    this.checkUrls();

    var request = new ReportFilesForMemberRequest();
    request.userName = this.currentUser;
    request.reportType = reportType;
    request.providerId = calProviderId;
    request.grgr_ck = grgr_ck;
    request.providerTin = providerTin;
    request.memberids.push(memberId);


    return this.http.post<MemberAWPStatus[]>(this.newWellnessForm ? this.getReportFilesForMemberAWP2Url : this.getReportFilesForMemberAWPUrl, request, httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return HttpResponseHelper.handleError(error);
        })
      );
  }

  public getReportFilesForTax(taxid: string, reportType: ReportType): Observable<ReportFile[]> {
    this.checkUrls();
    var request = new ReportFilesForTaxRequest();
    request.userName = this.currentUser;
    request.reportType = reportType;
    request.taxId = taxid;


    return this.http.post<ReportFile[]>(this.getReportFilesForTaxUrl, request, httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return HttpResponseHelper.handleError(error);
        })
      );
  }

  public getReportWellnessDetails(documentId: number): Observable<WellnessDetails2Extend> {
    this.checkUrls();
    return this.http.post<WellnessDetails2Extend>(this.getReportWellnessDetailsUrl, documentId, httpOptions)
      .pipe(
        map(data => {
          return data;
        }),
        catchError(error => {
          return HttpResponseHelper.handleError(error);
        })
      );
  }

  public getReportWellnessSurvey(wellnessYear: number, calProviderId: string, memberId: string, grgr_ck: number, providerTin: string, reportType: ReportType): Observable<wellnessSurvey[]> {
    this.checkUrls();

    var request = new wellnessRequest();
    request.reportType = reportType;
    request.pcpId = calProviderId;
    request.grgr_ck = grgr_ck;
    request.pcpTin = providerTin;
    request.memberCin = memberId;
    request.wellnessYear = wellnessYear;


    return this.http.post<wellnessSurvey[]>(this.getReportWellnessSurveyUrl, request, httpOptions)
      .pipe(
        map(data => {

          data.forEach(x => {
            if (x.type == "C" && x.answer) {
              x.checkedAnswer = x.answer.split("|");
            }
          });

          return data;
        }),
        catchError(error => {
          return HttpResponseHelper.handleError(error);
        })
      );
  }

  public downloadReportFileForProvider(calProviderId: string, fileName: string, reportType: ReportType): Observable<Blob> {
    this.checkUrls();
    var request = new ReportFileDownloadForProviderRequest();
    request.calProviderId = calProviderId;
    request.fileName = fileName;
    request.userName = this.currentUser;
    request.reportType = reportType;

    return this.http.post(this.getReportFileDownloadForProviderUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadReportFileForMember(calProviderId: string, memberId: string, fileName: string, reportType: ReportType): Observable<Blob> {
    this.checkUrls();
    var request = new ReportFileDownloadForProviderRequest();
    request.calProviderId = calProviderId;
    request.memberId = memberId;
    request.fileName = fileName;
    request.userName = this.currentUser;
    request.reportType = reportType;

    return this.http.post(this.getReportFileDownloadForProviderUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadMemberPcpRosterUsingProviderIds(providerIds: string[]): Observable<Blob> {
    this.checkUrls();
    var request = new MemberPcprosterDownloadRequest();
    request.username = this.currentUser;
    request.providerIds = providerIds;

    return this.http.post(this.downloadMemberPcpRosterUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadBHABAReferrals(providerIds:string[], startDate: Date, endDate: Date): Observable<Blob> {
    this.checkUrls();
    var request = new BHABAReferralDownloadRequest();
    request.username = this.currentUser;
    request.providerIds = providerIds;
    request.startDate = startDate;
    request.endDate = endDate;

    return this.http.post(this.downloadBHABAReferralUrl, request, {
        responseType: 'blob'
    });
  }

  public downloadIHADueMembersUsingProviderIds(providerIds:string[]): Observable<Blob> {
    this.checkUrls();
    var request = new MemberPcprosterDownloadRequest();
    request.username = this.currentUser;
    request.providerIds = providerIds;

    return this.http.post(this.downloadIHADueMemberUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadbhimembers(providerId: string, measurementPeriod: string[]): Observable<Blob> {
    this.checkUrls();
    var request = new BHIDownloadRequest();
    request.username = this.currentUser;
    request.calProviderId = providerId;
    request.measurementPeriod = measurementPeriod;

    return this.http.post(this.downloadbhimembersUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadHifMemberList(providerIds: string[]): Observable<Blob> {
    this.checkUrls();
    var request = new MemberWithHifDownloadRequest();
    request.username = this.currentUser;
    request.isExternalUser = !(this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser);
    request.providerIds = providerIds;

    return this.http.post(this.downloadHifMemberListUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadReferralSubmissionPdf(request: any): Observable<Blob> {
    this.checkUrls();
    return this.http.post(this.downloadReferralSudmissionPdfUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadReferralDetailPdf(request: any): Observable<Blob> {
    this.checkUrls();
    return this.http.post(this.downloadReferralDetailPdfUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadClaimDetailPdf(request: any): Observable<Blob> {
    this.checkUrls();
    return this.http.post(this.downloadClaimDetailPdfUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadMemberHifDetailPdf(request: MemberWithHifDownloadRequest): Observable<Blob> {
    this.checkUrls();
    request.username = this.currentUser;
    request.isExternalUser = !(this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser);
    return this.http.post(this.downloadMemberHifDetailpdfUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadAWPDetailPdf(documentId: number): Observable<Blob> {
    this.checkUrls();

    var request = new AWPDownloadRequest();
    request.documentId = documentId;
    request.isInternalUser = (this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser);

    return this.http.post(this.downloadAWPDetailpdfUrl, request, {
      responseType: 'blob'
    });
  }

  public downloadUnansweredAWPDetailPdf(documentId: number): Observable<Blob> {
    this.checkUrls();

    var request = new AWPDownloadRequest();
    request.documentId = documentId;
    request.isInternalUser = (this.sessionService.isEnterpriseAdmin || this.sessionService.isEnterpriseUser);

    return this.http.post(this.downloadUnansweredAWPDetailpdfUrl, request, {
      responseType: 'blob'
    });
  }

  public getProvidersWithAttestationForReports(collectionId: number, collectionName: string, reportType: ReportType): Observable<ProviderDetailWithAttestation[]> {

    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.collectionId = collectionId;
      request.fetchRows = this.constants.ReportMaxRows;
      request.pcpOnly = false;
      request.reportType = reportType;
      request.collectionName = collectionName;

      return this.http
        .post<ProviderDetailWithAttestation[]>(this.getProvidersWithAttestationUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );

    }
    catch (ex) {

    }
  }

  public searchProvidersWithAttestationForReports(providerId: string, providerName: string, reportType: ReportType): Observable<ProviderDetailWithAttestation[]> {
    this.checkUrls();

    try {
      const request = new ProviderSearchReportRequest();

      request.userName = this.currentUser;
      request.providerName = providerName;
      request.calProviderId = providerId;
      request.reportType = reportType;

      return this.http
        .post<ProviderDetailWithAttestation[]>(this.searchProvidersWithAttestationUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {

    }
  }

  public getAttestationListForProvider(calProviderId: string, reportType: ReportType): Observable<AttestationDetail[]> {
    this.checkUrls();

    try {
      const request = new AttestationRequest();

      request.ReportType = reportType;
      request.PcpId = calProviderId;

      return this.http
        .post<AttestationDetail[]>(this.getAttestationListForProviderUrl, request, httpOptions)
        .pipe(
          map((result) => {

            return result;
          }),
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {

    }
  }

  public ApplyAttestation(attestationDetailList: AttestationDetail[]): Observable<void> {
    this.checkUrls();

    try {
      return this.http
        .post<void>(this.applyAttestationUrl, attestationDetailList, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {

    }
  }

  public ApplyWellness(wellnessDetails: WellnessDetails): Observable<void> {
    this.checkUrls();

    try {

      return this.http
        .post<void>(this.applyWellnessUrl, wellnessDetails, httpOptions)
        .pipe(
          publishReplay(1),
          refCount(),
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {

    }
  }

  public ApplyWellness2(wellnessDetails2: WellnessDetails2Extend): Observable<void> {
    this.checkUrls();

    try {

      return this.http
        .post<void>(this.newWellnessForm ? this.applyWellness2Url : this.applyWellnessUrl, wellnessDetails2, httpOptions)
        .pipe(
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
    }
  }

  public ApplyWellness2Save(wellnessDetails2: WellnessDetails2Extend): Observable<void> {
    this.checkUrls();

    try {

      return this.http
        .post<void>(this.applyWellness2SaveUrl, wellnessDetails2, httpOptions)
        .pipe(
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
    }
  }

  public ApplyWellnessSurveyAnswers(wellnessYear: number, calProviderId: string, memberId: string, grgr_ck: number, reportType: ReportType, wellnessSurveys: wellnessSurvey[]): Observable<void> {
    this.checkUrls();

    try {

      var wellnessSurveyQuestionAnswers: wellnessSurveyAnswer[] = [];
      wellnessSurveys.forEach(element => {
        var item: wellnessSurveyAnswer = new wellnessSurveyAnswer();
        item.surveyQuestionId = element.id;
        item.surveyQuestionAnswer = element.answer;
        wellnessSurveyQuestionAnswers.push(item);
      });

      var request = new WellnessSurveyAnswers();
      request.reportType = reportType;
      request.pcpId = calProviderId;
      request.grgr_ck = grgr_ck;
      request.memberCin = memberId;
      request.wellnessYear = wellnessYear;
      request.surveyAnswers = wellnessSurveyQuestionAnswers;

      return this.http
        .post<void>(this.applyWellnessSurveyAnswersUrl, request, httpOptions)
        .pipe(
          catchError(error => HttpResponseHelper.handleError(error))
        );
    }
    catch (ex) {
      console.log(ex);
    }
  }
}
