import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, AfterViewInit, Input, Provider } from '@angular/core';
import { MemberAWPStatus } from 'src/app/services/models/awp-detail';
import { BusyService } from '@caloptima/portal-foundation';
import { ReportsService } from 'src/app/services/reports.service';
import { WellnessDetails2Extend } from 'src/app/services/models/wellness-details';
import { ReportMemberDetails } from 'src/app/services/models/reportmemberdetails';
import { forkJoin, retry } from 'rxjs';
import { FileService } from 'src/app/services/file.service';
import { WellnessSearchData } from 'src/app/services/models/wellness-attachment';
import { ReportType } from 'src/app/services/models/reportype';
import { wellnessSurvey } from 'src/app/services/models/wellnessSurvey';
import { saveAs } from 'file-saver';
import { OAuthService } from 'angular-oauth2-oidc';
import { CollectionService } from 'src/app/services/collection.service';
import { MemberInfoService } from 'src/app/services/member-info.service';
import { Member } from 'src/app/services/models/member';
import { ProviderDetail } from 'src/app/services/models/provider-detail';
import { ProviderDetail2 } from 'src/app/services/models/provider-detail2';
import { AlertDangerComponent } from '../../alerts/alert-danger/alert-danger.component';
import { ReportWellnessDetailsComponent } from '../../report-wellness-details/report-wellness-details.component';
import { ReportWellnessAttachmentsComponent } from '../../report-wellness-attachments/report-wellness-attachments.component';
import { ReportWellnessSurveyComponent } from '../../report-wellness-survey/report-wellness-survey.component';

@Component({
  selector: 'report-wellness-details-dialog',
  templateUrl: './report-wellness-details-dialog.component.html',
  styleUrls: ['./report-wellness-details-dialog.component.scss']
})
export class ReportWellnessDetailsDialogComponent implements OnInit, OnDestroy {

  @Input() public selectedMemberAWPStatus: MemberAWPStatus;
  @Input() public selectedMember: ReportMemberDetails;
  @Input() public selectedProvider: ProviderDetail;
  @Output() cancelWellness = new EventEmitter();
  @Output() applyWellness = new EventEmitter();
  public currentView: number = 1;
  public wellnessFileList: WellnessSearchData[];
  public wellnessDetails: WellnessDetails2Extend;
  public wellnessSurveys: wellnessSurvey[];
  public providerDetail: ProviderDetail2;
  public memberDetail: Member;
  public attachmentProgress: number = null;
  public attachments2: any[] = [];
  public hasUploadError2: boolean = false;
  public maxUploadFilesSupported2 = 5;
  private totalFilesSize: number = 100000000;
  private maxFileNameLength = 128;
  public isLoaded: boolean = false;
  public wellnessFormChanged: boolean = false;
  public wellnessAttachmentChanged: boolean = false;
  public errorMessage: string = '';
  public currentUser: string;
  public isskipped: boolean = false;
  @ViewChild('alertdangermessage') alertDangerComponent: AlertDangerComponent;;
  @ViewChild('wellnessdetailscomponent') wellnessdetailscomponent: ReportWellnessDetailsComponent;
  @ViewChild('wellnessattachmentcomponent') wellnessattachmentcomponent: ReportWellnessAttachmentsComponent;
  @ViewChild('surveycomponent') surveycomponent: ReportWellnessSurveyComponent;

  constructor(
    private reportService: ReportsService,
    private fileService: FileService,
    private memberService: MemberInfoService,
    private collectionService: CollectionService,
    private busyService: BusyService,
    private oauth: OAuthService
  ) {
  }

  ngOnInit() {
    this.LoadData();
  }

  LoadData() {
    forkJoin([
      this.fileService.getWellnessAttachment(this.selectedMember.cin, this.selectedProvider.calProviderID, this.selectedProvider.taxId, this.selectedMemberAWPStatus.period),
      this.reportService.getReportWellnessDetails(this.selectedMemberAWPStatus.documentId),
      this.reportService.getReportWellnessSurvey(this.selectedMemberAWPStatus.period, this.selectedMember.providerId, this.selectedMember.cin, this.selectedMember.grgr_ck, this.selectedMember.providerTaxId, ReportType.AWV),
      this.collectionService.GetProvider2(this.selectedProvider.calProviderID)
    ]).subscribe(
      (result) => {
        if (result && result.length > 1) {
          this.wellnessFileList = result[0];
          this.wellnessDetails = result[1];
          this.wellnessSurveys = result[2];
          this.providerDetail = result[3];

          this.wellnessFileList.forEach(x=>{
            let content = "1";
            let data = new Blob([content], { type: 'application/txt' });
            let arrayOfBlob:Blob[] = [];
            arrayOfBlob.push(data);
            let tempFile = new File(arrayOfBlob, x.originalFileName);
      
            if(!x.isWellnessForm)
              this.attachments2.push(tempFile);
          });  

          const claims = this.oauth.getIdentityClaims();
          if (claims) {
            this.currentUser = claims['sub'];
          }

          this.isLoaded = true;
          this.isskipped = false;
        }
      }
    );
  }

  ngOnDestroy() {
  }

  public fileAdded(event) {
    this.attachments2 = event;
    this.wellnessAttachmentChanged = true;
  }

  public fileRemoved(event) {
    this.attachments2 = event;
    this.wellnessAttachmentChanged = true;
  }

  public onWellnessFormChanges($event) {
    this.wellnessFormChanged = true;
  }

  public get display(): boolean {
    return true;
  }

  public set display(value: boolean) {
    this.onCancel();
  }

  public onCancel() {
    this.cancelWellness.emit();
    this.display = false;
  }

  public onCancelWellness() {
    if (this.currentView === 1) {
      this.cancelWellness.emit();
      this.display = false;
    }
    else if (this.currentView === 2) {
      this.currentView = 1;
    }
    else if (this.currentView === 3) {
      this.currentView = 2;

      setTimeout(() => {
        this.wellnessattachmentcomponent.setAttachments(this.attachments2);
      }, (500));                
    }
  }

  public onSkip() {
    this.wellnessDetails.diagnosisReCaptured.forEach(x=>{
      x.status = null;
    });

    this.wellnessDetails.otherDiagnosis.splice(0);

    this.wellnessDetails.screening.forEach(x=>{
      x.status = null;
      x.completionDate = null;
    });

    this.wellnessDetails.additionalComments = null;
    this.wellnessDetails.notes = null;
    this.wellnessDetails.dateOfService = null;  

    this.isskipped = true;
    this.hasUploadError2 = false;
    this.errorMessage = "";
    this.currentView = 2;

    setTimeout(() => {
      this.wellnessattachmentcomponent.setAttachments(this.attachments2);
    }, (500));    
  }

  public onSaveAndContinueLater() {

    this.fixDates();

    this.wellnessDetails.modifiedBy = this.currentUser;
    this.hasUploadError2 = false;
    this.errorMessage = "";    

    this.busyService.emit(true);
    forkJoin([
      this.reportService.ApplyWellness2Save(this.wellnessDetails),
      this.reportService.ApplyWellnessSurveyAnswers(this.wellnessDetails.wellnessYear, this.selectedMember.providerId, this.selectedMember.cin, this.selectedMember.grgr_ck, ReportType.AWV, this.wellnessSurveys)
    ]).subscribe((result) => {

      this.uploadWellnessForm();
    },
      (error) => {
        this.hasUploadError2 = true;
        this.errorMessage = "Error submitting the wellness form. Try submitting again !!!"
        this.busyService.emit(false);
      });
  }

  public performWellnessFormValidation() {
    this.hasUploadError2 = false;
    this.errorMessage = "";

    if (!this.hasUploadError2) {
      if (this.wellnessDetails.dateOfService == null || this.wellnessDetails.dateOfService.toString() == "") {
        this.hasUploadError2 = true;
        this.errorMessage = "Date of service required to submit this form."
      };
    }

    if (!this.hasUploadError2) {
      this.wellnessDetails.screening.forEach(x => {
        if (x.status && x.status.trim().toUpperCase() === "COMPLETED" && (x.completionDate == null || x.completionDate.toString() == "")) {
          this.hasUploadError2 = true;
          this.errorMessage = "Date Completed is required for Completed screenings."
        }
      });
    }

    if (!this.hasUploadError2) {
      this.wellnessDetails.diagnosisReCaptured.forEach(element => {
        if (element.isChronic && element.isChronic.trim().toUpperCase() === "Y") {
          if (element.status == null || element.status == "") {
            this.hasUploadError2 = true;
            this.errorMessage = "Status required for Chronic Condition."
          }
        }
      });
    }

    if (!this.hasUploadError2) {
      this.wellnessDetails.diagnosisReCaptured.forEach(element => {
        if (element.isChronic && element.isChronic.trim().toUpperCase() === "N") {
          if (element.status == null || element.status == "") {
            this.hasUploadError2 = true;
            this.errorMessage = "Status required for Non-Chronic Condition."
          }
        }
      });
    }

    if (!this.hasUploadError2) {
      this.wellnessDetails.otherDiagnosis.forEach(element => {
        if (element.status == null || element.status == "") {
          this.hasUploadError2 = true;
          this.errorMessage = "Status required for Other Diagnosis."
        }
      });
    }

    if (this.hasUploadError2) {
      if (this.alertDangerComponent) {
        this.alertDangerComponent.contentMessage = this.errorMessage;
        this.alertDangerComponent.setContentMessages()
      }
    }
  }

  public performAttachmentValidation() {
    this.hasUploadError2 = false;
    this.errorMessage = "";

    if(this.isskipped){

      var fileExists: boolean = false;
      this.wellnessFileList.forEach(x=>{
        if(!x.isWellnessForm)
          fileExists = true;
      })
      if(!fileExists) {
        fileExists = this.attachments2.length > 0;
      }

      if(!fileExists) {
        this.hasUploadError2 = true;
        this.errorMessage = "Upload the signed document and any supporting documents you may have.";
      }
    }
  }

  private fixDates() {
    if (this.wellnessDetails.dateOfService != null && this.wellnessDetails.dateOfService.toString() == "") {
      this.wellnessDetails.dateOfService = null;
    };

    this.wellnessDetails.screening.forEach(x => {
      if (x.completionDate != null && x.completionDate.toString() == "") {
        x.completionDate = null;
      }
    });

    this.wellnessDetails.otherDiagnosis.forEach(x => {
      if (x.dateOfService != null && x.dateOfService.toString() == "") {
        x.dateOfService = null;
      }
    });
  }

  public onApplyWellness() {
    if (this.currentView === 1) {

      this.performWellnessFormValidation();
      if (!this.hasUploadError2) {
        this.isskipped = false;
        this.currentView = 2;

        setTimeout(() => {
          this.wellnessattachmentcomponent.setAttachments(this.attachments2);
        }, (500));                        
      }
    }
    else if (this.currentView === 2) {

      this.performAttachmentValidation();
      if(!this.hasUploadError2){
        this.currentView = 3;
      }            
    }
    else if (this.currentView === 3) {

      this.fixDates();

      this.selectedMemberAWPStatus.status = "Completed";
      this.wellnessDetails.completionDate = new Date();
      this.wellnessDetails.modifiedBy = this.currentUser;
      this.hasUploadError2 = false;
      this.errorMessage = "";

      this.busyService.emit(true);
      forkJoin([
        this.reportService.ApplyWellness2(this.wellnessDetails),
        this.reportService.ApplyWellnessSurveyAnswers(this.wellnessDetails.wellnessYear, this.selectedMember.providerId, this.selectedMember.cin, this.selectedMember.grgr_ck, ReportType.AWV, this.wellnessSurveys)
      ]).subscribe((result) => {

        this.uploadWellnessForm();
      },
        (error) => {
          this.hasUploadError2 = true;
          this.errorMessage = "Error submitting the wellness form. Try submitting again !!!"
          this.busyService.emit(false);
        });
    }
  }

  public uploadWellnessForm() {

    var wellnessFormExits: WellnessSearchData = this.wellnessFileList.find(x => x.isWellnessForm);

    if (this.wellnessFormChanged || !wellnessFormExits) {
      this.reportService.downloadAWPDetailPdf(this.wellnessDetails.documentId).subscribe((wellnessForm) => {
        this.uploadSupportingDocuments(wellnessForm);
      },
        (error) => {
          this.hasUploadError2 = true;
          this.errorMessage = "Error generating the wellness form. Try submitting again !!!"
          this.busyService.emit(false);
        });
    }
    else {
      this.uploadSupportingDocuments(null);
    }
  }

  public uploadSupportingDocuments(wellnessForm: Blob) {
    var formData: FormData = this.getFormData(wellnessForm);
    if (formData) {
      this.fileService.uploadWellnessAttachments(formData).subscribe(x => {
        this.busyService.emit(false);
        this.applyWellness.emit();
      },
        (error) => {
          this.hasUploadError2 = true;
          this.errorMessage = "Error uploading the attachments. Try submitting again !!!"
          this.busyService.emit(false);
        });
    }
    else {
      this.busyService.emit(false);
      this.applyWellness.emit();
    }
  }

  public get getSubmitButtonText() {
    if (this.currentView === 1) {
      return "Next";
    }
    else if (this.currentView === 2) {
      return "Next";
    }
    else if (this.currentView === 3)
      return "Submit"
  }

  public get getCancelButtonText() {
    if (this.currentView === 1) {
      return "Cancel";
    }
    else if (this.currentView === 2) {
      return "Back";
    }
    else if (this.currentView === 3) {
      return "Back";
    }
  }

  public get getHeaderText(): string {
    return this.selectedMember ? this.selectedMember.memberName : "";
  }

  public get wellnessFormFileName(): string {
    var fileName: string = "AWP_PROV_" +
      this.providerDetail.providerTaxId + "_" +
      this.wellnessDetails.wellnessYear.toString() + "_" +
      this.wellnessDetails.pcpId + "_" +
      this.wellnessDetails.memberCIN + ".pdf";
    return fileName;
  }

  public getNewWellnessFormFileName(): string {

    var fileName: string = "AWP_PROV_" +
      this.providerDetail.providerTaxId + "_" +
      this.wellnessDetails.wellnessYear.toString() + "_" +
      this.wellnessDetails.pcpId + "_" +
      this.wellnessDetails.memberCIN + "_wellnessForm";

    var temp = fileName + ".pdf";
    var iloop = 1;

    while (this.doesExists(temp) || this.doesExists2(temp)) {
      temp = fileName + "_" + iloop.toString() + ".pdf";
      iloop++;
    }

    return temp;
  }

  public getFormData(mainWellnessFormFile: Blob): FormData {
    let wellnessFormFiles = '';
    let fileToUpload: File[] = [];
    const formData = new FormData();

    if (mainWellnessFormFile) {
      let wellnewFile: File = new File([mainWellnessFormFile], this.getNewWellnessFormFileName());
      fileToUpload.push(wellnewFile);
      wellnessFormFiles += wellnewFile.name + "|";
    }  

    this.attachments2.forEach(element => {
      if (!this.doesExists(element.name)) {
        fileToUpload.push(element);
      }
    });

    if (fileToUpload.length > 0) {

      let totalSize = 0;
      Array.from(fileToUpload)
        .map((file, index) => {
          totalSize += file.size;
          return formData.append('file' + index, file, file.name);
        });

      formData.append('memberId', this.selectedMember.cin);
      formData.append('providerid', this.selectedMember.providerId);
      formData.append('providertaxid', this.providerDetail.providerTaxId);
      formData.append('wellnessyear', this.selectedMemberAWPStatus.period.toString());

      if (wellnessFormFiles != '')
        formData.append('wellnessFiles', wellnessFormFiles);

      return formData;
    }

    return null;
  }

  public doesExists(fileName: string): boolean {
    var temp = this.wellnessFileList.find(x => x.originalFileName.trim().toUpperCase() === fileName.trim().toUpperCase())
    return temp ? true : false;
  }

  public doesExists2(fileName: string): boolean {
    var temp = this.attachments2.find(x => x.name.trim().toUpperCase() === fileName.trim().toUpperCase())
    return temp ? true : false;
  }

  public onPrintToPdf() {
    if (this.isLoaded) {
      this.reportService.downloadAWPDetailPdf(this.wellnessDetails.documentId).subscribe(result => {

        const data: Blob = new Blob([result], { type: 'text/pdf' });
        saveAs(data, this.wellnessFormFileName);
        this.busyService.emit(false);
      });
    }
  }

  public get isSubmitted(): boolean {
    return this.wellnessDetails && this.wellnessDetails.completionDate != null;
  }
}

