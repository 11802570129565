
<p-dialog class="browser-cache-dialog" header="Update Required"  [style]="{width: '38rem'}"  [(visible)]="display" [modal]="true" (onHide)="onCancel()">
  <div class="browser-cache-container">
      <p>In order to continue, a mandatory update to Provider Portal is required.</p>
  </div>
  <p-footer class="browser-cache-footer">
    <div class="buttons">
      <button type="button" class="btn btn-secondary btn-confirm-notnow" (click)="onReject()">Not Now</button>
      <button type="button" class="btn btn-primary btn-confirm-accept" (click)="onAccept()">Accept</button>
    </div>
  </p-footer>
</p-dialog>
