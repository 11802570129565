<div>
    <div>
        Do you have any supporting documents? Acceptable documents include *.pdf, *.docx, *.xlsx, and images files (5 items total)
    </div>            
    <div>
        <p-fileUpload #fileUpload2 class="prime-file-upload" chooseLabel="Choose File"
        (onSelect)="onSelect($event)"
        multiple="multiple"
        accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.txt,.csv,.xls,.xlsx,.bmp,.tif,.xps,.rtf,.msg"
        maxFileSize="25000000"
        fileLimit="{{maxUploadFilesSupported2}}"
        [disabled]="!enableUpload"
        [showUploadButton]="false"
        [showCancelButton]="false">        
        <ng-template pTemplate="content">
            <div *ngIf="attachments2.length === 0">
                <div class="referral-submission-attach-summary">
                    <div class="referral-submission-attach-summary-icon">
                        <span><i class="fa fa-cloud-upload"></i></span>
                    </div>
                    <div class="referral-submission-attach-summary-content">
                        There are 0 supporting documents for this member.
                    </div>
                </div>
            </div>
            <ul *ngIf="attachments2.length" class="report-wellness-attach-list">
                <li *ngFor="let file of attachments2">
                    <div [ngSwitch]="fileType(file.name)" style="display: inline;">
                        <div *ngSwitchCase="'pdf'" class="file-type">
                            <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-pdf-o"></i></span>
                        </div>
                        <div *ngSwitchCase="'docx'" class="file-type">
                            <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-word-o"></i></span>
                        </div>
                        <div *ngSwitchCase="'csv'" class="file-type">
                            <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-excel-o"></i></span>
                        </div>
                        <div *ngSwitchCase="'file'" class="file-type">
                            <span class="report-wellness-attach-body-icon"> <i class="fa fa fa-file-text-o"></i></span>
                        </div>
                        <div *ngSwitchCase="'img'" class="file-type">
                            <span class="report-wellness-attach-body-icon"> <i class="fa fa-file-image-o"></i></span>
                        </div>
                        <div *ngSwitchDefault class="file-type">
                            <span class="report-wellness-attach-body-icon"> <i class="fa fa fa-file-text-o"></i></span>
                        </div>
                    </div>
                    <div *ngIf="doesExists(file.name)" class="file-name">
                        <a href='javascript:void(0);' (click)="onDownload(file.name)">{{file.name}}</a>
                    </div>
                    <div *ngIf="!doesExists(file.name)" class="file-name">
                        {{file.name}}
                    </div>                
                    <div class="file-size">
                        {{fileSize(file.name)}}
                    </div>
                    <div *ngIf="!doesExists(file.name)" class="file-action">
                        <button type="button" icon="fa fa-times" pButton (click)="onRemove2(event, file)"></button>
                    </div>
                </li>
            </ul>
        </ng-template>
        </p-fileUpload>        
        <div class="report-wellness-attach-body-footer">
            Upload up to {{maxUploadFilesSupported2}} files. Max file size 25MB.
        </div>
        <div class="report-wellness-attach-body-footer" *ngIf="attachmentError">
            <app-alert-danger
                [subjectMessage]="''"
                [contentMessage]="'Error in submitting annual wellness files. Please try to re-submit.'">
            </app-alert-danger>
        </div>       
    </div>
</div>
